import { z } from "zod";

export const rfiZodSchema = z.object({
  degreeType: z
    .object({
      text: z.string().optional(),
      value: z.string().optional(),
    })
    .optional()
    .nullable(),
  interestArea: z
    .object({
      text: z.string().optional(),
      value: z.string().optional(),
    })
    .optional()
    .nullable(),
  program: z.object({
    text: z.string(),
    value: z.string(),
  }),
  firstName: z
    .string({ required_error: "This is a required field." })
    .max(30, {
      message: "The first name should not be more than 30 characters.",
    })
    .regex(
      /^[a-zA-Z-\'\`\'\'\u2019\u2018\u0027\u0060\u00b4]+( [a-zA-Z-\'\`\'\'\u2019\u2018\u0027\u0060\u00b4]+)*$/,
      {
        message:
          "First name can only contain letters, spaces and allowed special characters ( ', `, - ).",
      }
    ),
  lastName: z
    .string({ required_error: "This is a required field." })
    .max(30, {
      message: "The last name should not be more than 30 characters.",
    })
    .regex(
      /^[a-zA-Z-\'\`\'\'\u2019\u2018\u0027\u0060\u00b4]+( [a-zA-Z-\'\`\'\'\u2019\u2018\u0027\u0060\u00b4]+)*$/,
      {
        message:
          "Last name can only contain letters, spaces and allowed special characters ( ', `, - ).",
      }
    ),
  email: z
    .string({ required_error: "This is a required field." })
    .email({
      message: "Email is required. Please enter a valid email address.",
    })
    .max(128, {
      message: "The email should not be more than 128 characters.",
    }),
  phone: z.object({
    number: z.coerce.string().min(1),
    formattedNumber: z.coerce.string().min(1),
    nationalNumber: z.coerce.string().min(1),
    isValid: z.boolean(),
    countryCode: z.coerce.string().min(1),
  }),
  isMilitary: z.boolean(),
});
export type RfiFormFieldShape = z.infer<typeof rfiZodSchema>;
