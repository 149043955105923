<template>
  <MazPhoneNumberInput
    class="maz-phone-input"
    :id="id"
    ref="phoneInputWrapper"
    @update="handleUpdate"
    @vue:before-unmount="removeMoutedAttributes"
    @vue:mounted="addMoutedAttributes"
  />
</template>

<script setup lang="ts">
// component wraps up the MazPhoneNumberInput because it's the only element all attributes added when using this componet passthrough to the maz component.
import MazPhoneNumberInput, {
  Results,
} from "maz-ui/components/MazPhoneNumberInput";
// importing root values required for other css to work, can't scope these root values, using vite import to prevent dupes at bundle time
import "~/assets/scss/maz-custom/maz-root-values.scss";

const props = defineProps<{
  id: string;
}>();

const phoneInput = ref<HTMLInputElement | null | undefined>();
const emits = defineEmits<{
  (e: "blur"): void;
  (e: "update", results: Results): void;
}>();

const handleBlur = () => {
  emits("blur");
};
const handleUpdate = (results: Results) => {
  emits("update", results);
};

const addMoutedAttributes = () => {
  if (window) {
    phoneInput.value = document.getElementById(props.id) as HTMLInputElement;

    if (phoneInput.value) {
      // adding blur event listener
      phoneInput.value.addEventListener("blur", () => {
        handleBlur();
      });
      // adding data-cy for testing, attributes don't pass down when using maz
      phoneInput.value.setAttribute("data-cy", "rfi-phone-number");
    }
  }
};

const removeMoutedAttributes = () => {
  if (window && phoneInput.value) {
    phoneInput.value.removeEventListener("blur", () => {
      handleBlur();
    });
  }
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "~/assets/scss/maz-custom/maz-stripped-down";
  button {
    background-color: transparent;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 1em;
    line-height: 1.15;
    margin: 0;
  }
}

:deep(.m-country-selector__country-flag) {
  width: 24px !important;
}
</style>
