<template>
  <p class="fs-xs mb-0" :class="`fs-${props.textClass}`">
    By submitting my information, I consent to ASU contacting me about
    educational services using automated calls, prerecorded voice messages,
    SMS/text messages or email at the information provided above. Message and
    data rates may apply. Consent is not required to receive services, and I may
    call ASU directly at
    <a
      href="tel:8662776589"
      @click.prevent="handlePhoneClick"
      class="non-rds-link"
    >
      866-277-6589</a
    >. I consent to ASU’s
    <a
      href="/text-terms/"
      @click.prevent="handleTermsClick"
      class="non-rds-link"
    >
      mobile terms and conditions</a
    >, and
    <a
      href="https://asuonline.asu.edu/web-analytics-privacy-2/"
      @click.prevent="handleAnalyticsClick"
      class="non-rds-link"
    >
      Privacy Statements</a
    >, including the European Supplement.
  </p>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";

interface Props {
  textClass?: "gdpr" | "xs" | "small" | "medium" | "large" | "xl";
}
const props = withDefaults(defineProps<Props>(), {
  textClass: "xs",
});

const handlePhoneClick = (e: Event) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "request info",
    "866-277-6589"
  );
  const evnt = e.target as HTMLInputElement;
  const href = evnt.getAttribute("href");
  navigateTo(href, {
    open: {
      target: "_blank",
    },
  });
};

const handleTermsClick = (e: Event) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "request info",
    "mobile terms and conditions"
  );
  const evnt = e.target as HTMLInputElement;
  const href = evnt.getAttribute("href");
  navigateTo(href, {
    open: {
      target: "_blank",
    },
  });
};
const handleAnalyticsClick = (e: Event) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "request info",
    "privacy statements"
  );
  const evnt = e.target as HTMLInputElement;
  const href = evnt.getAttribute("href");
  navigateTo(href, {
    open: {
      target: "_blank",
    },
  });
};
</script>

<style scoped></style>
