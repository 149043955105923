"use strict";

import { DegreeSeekingProgram } from "~/server/resolvers/resolvers-types";
import { FormattedProgram, WithRequired } from "~/types/types";

export type CategoryProgramsMap = {
  [id: string]: FormattedProgram[];
};

// export functions
/**
 * @param {Array<string>} categories
 * @param {Array<FormattedProgram>} programs
 */
function generateCategoryProgramsMap(
  categories: Array<string>,
  programs: Array<FormattedProgram>
) {
  /**
   * @type {CategoryProgramsMap}
   */
  const map: CategoryProgramsMap = {};
  categories.forEach((category) => {
    map[category] = [];
  });
  programs.forEach((program) => {
    map[program.category].push(program);
  });

  return map;
}

/**
 * Generates collection of programs with limited number of properties and un-nests them from edge.node.
 *
 */
function generateFormattedProgramCollection(programs: DegreeSeekingProgram[]) {
  /**
   * @type {Array<FormattedProgram>}
   */
  const result: Array<WithRequired<FormattedProgram, "interestAreas">> = [];
  programs.forEach((program) => {
    const areas: string[] = [];
    program.interestAreas!.forEach((area) => {
      areas.push(area!.title!);
    });
    result.push({
      id: program.id,
      code: program.code!,
      title: program.title!,
      category: program.category!.title!,
      interestAreas: areas,
    });
  });
  return result;
}

export default {
  generateFormattedProgramCollection: generateFormattedProgramCollection,
  generateCategoryProgramsMap: generateCategoryProgramsMap,
};
